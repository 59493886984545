import React from 'react';
import {string} from 'prop-types';

import {StyledLink} from './Button.styles';

const Button = ({to, text, variant}) => (
  <StyledLink to={to} variant={variant}>{text}</StyledLink>
);

Button.propTpes = {
  to: string.isRequired,
  text: string.isRequired,
}

Button.defaultProps = {
  variant: 'blue',
}

export default Button;


