import React from 'react';
import {arrayOf, shape} from 'prop-types';
import {FormattedMessage} from 'gatsby-plugin-intl';

import {Title, ModulesWrapper, Module} from './TrainingModules.styles';
import messages from './messages';

const TrainingModules = ({modules}) => (
  <div>
    <Title><FormattedMessage {...messages.title}/></Title>
    <ModulesWrapper>
      {modules.map((module, i) => (
        <Module key={i}><FormattedMessage {...module}/></Module>
      ))}
    </ModulesWrapper>
  </div>
);

TrainingModules.propTypes = {
  modules: arrayOf(shape({})).isRequired,
};

export default TrainingModules;
