import styled from 'styled-components';

import {MEDIA} from '../../constants';
import {getTypographySize, getTypographyColor, getTypographyWeight, getSpacing, getColor} from '../../theme/helpers';

export const Title = styled.h2`
  font-size: ${getTypographySize('xl')};
  font-weight: ${getTypographyWeight('normal')};
  color: ${getTypographyColor('brandGreen')};
  padding: ${getSpacing('basic')} 0;
  text-align: center;

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('xxl')};
    text-align: left;
  }
`;

export const ModulesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${getSpacing('basic')} 0;
`;

export const Module = styled.div`
  border: 2px solid ${getColor('brandGreen')};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  width: 100%;
  color: ${getTypographyColor('brandBlue')};
  text-align: center;
  font-size: ${getTypographySize('xmd')};
  padding: ${getSpacing('basic')};
  margin: ${getSpacing('xs')} 2px;

  @media (min-width: ${MEDIA.MIN}) {
    width: 49%;
  }

  @media (min-width: ${MEDIA.MED}) {
    width: 24%;
  }
`;
