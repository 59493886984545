import React from 'react';

import TrainingPageContent from '../TrainingPageContent';
import {TRAINING_PAGE_TITLE, TRAINING_PAGE_LINK} from '../../../constants';
import {TRAINING_CONTENT, TRAINING_MODULES} from './config';

const CQRSAxonTraining = () => (
  <TrainingPageContent
    title={TRAINING_CONTENT.title}
    descriptions={TRAINING_CONTENT.descriptions}
    modules={TRAINING_MODULES}
    timeframe={TRAINING_CONTENT.timeframe.amount}
    nextTraining={{
      name: TRAINING_PAGE_TITLE.DDD_APPLIED,
      link: TRAINING_PAGE_LINK.DDD_APPLIED,
    }}
  />
);

export default CQRSAxonTraining;
