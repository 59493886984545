import React from 'react';
import {string, arrayOf, shape} from 'prop-types';
import {FormattedMessage, useIntl} from 'gatsby-plugin-intl';

import Container from '../Container';
import messages from './messages';
import {
  Wrapper,
  TrainingTitle,
  ProgramInfo,
  Description,
  TimeframeWrapper,
  CalendarIcon,
  TimeframeText,
  TrainingContactSection,
  TrainingContactText,
  TrainingContactButton,
  CheckTrainingText,
} from './TrainingPageContent.styles';
import TrainingModules from '../TrainingModules';
import Button from '../Button';
import {CONTACT_EMAIL_ADDRESS} from '../../constants';
import calendarImg from '../../../static/calendar.svg';

const TrainingPageContent = ({title, descriptions, modules, timeframe, nextTraining}) => {
  const {formatMessage} = useIntl();

  return (
    <Wrapper>
      <Container>
        <ProgramInfo><FormattedMessage {...messages.programInfo}/></ProgramInfo>
        <TrainingTitle><FormattedMessage {...title}/></TrainingTitle>
        {descriptions.length > 0 && descriptions.map((desc, i) => (
          <Description key={i}><FormattedMessage {...desc}/></Description>
        ))}
        <TrainingModules modules={modules}/>
        <TimeframeWrapper>
          <CalendarIcon src={calendarImg} alt={formatMessage(messages.calendarImgAlt)}/>
          <TimeframeText>
            <FormattedMessage {...messages.timeframe}/> {timeframe} <FormattedMessage {...messages.days}/>
          </TimeframeText>
        </TimeframeWrapper>
      </Container>
      <TrainingContactSection>
        <TrainingContactText>
          <FormattedMessage {...messages.contactText}/>
        </TrainingContactText>
        <TrainingContactButton
          href={`mailto:${CONTACT_EMAIL_ADDRESS}`}
        >
          <FormattedMessage {...messages.contactButtonMsg}/>
        </TrainingContactButton>
        <CheckTrainingText><FormattedMessage {...messages.checkOtherMsg}/></CheckTrainingText>
        <Button text={nextTraining.name} to={nextTraining.link}/>
      </TrainingContactSection>
    </Wrapper>
  );
}

TrainingPageContent.propTypes = {
  title: shape({}).isRequired,
  descriptions: arrayOf(shape({})),
  modules: arrayOf(shape({})).isRequired,
  timeframe: string.isRequired,
  nextTraining: shape({
    name: string.isRequired,
    link: string.isRequired,
  }).isRequired,
};

TrainingPageContent.defaultProps = {
  descriptions: [],
}

export default TrainingPageContent;
