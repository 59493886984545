const scope = 'components.TrainingPageContent.CQRSAxonTraining';

export default {
  title: {
    id: `${scope}.title`,
    defaultMessage: 'CQRS and Event Sourcing Applied with Axon Framework',
  },
  timeUnit: {
    id: `${scope}.timeUnit`,
    defaultMessage: 'dni',
  },
  description_01: {
    id: `${scope}.description_01`,
    defaultMessage: 'Dowiedz się dlaczego zdarzenia biznesowe są idealnym rozwiązaniem dla systemów informacyjnych.',
  },
  description_02: {
    id: `${scope}.description_02`,
    defaultMessage: 'Wykorzystaj je, aby zautomatyzować Twoje procesy biznesowe w sposób prosty i przejrzysty zarówno dla programistów, jak i biznesu. Poznaj Axon Framework, który w naturalny sposób wspiera Command-Query Responsibility Segregation, oraz Event Sourcing w Javie, Scali i Kotlinie.',
  },
  description_03: {
    id: `${scope}.description_03`,
    defaultMessage: 'Weź udział w wysoce interaktywnym warsztacie i zobacz jak wprowadzić architekturę CQRS',
  },
  module_01: {
    id: `${scope}.module_01`,
    defaultMessage: 'Journey Module I - Stan aktualny i wprowadzenie do DDD',
  },
  module_02: {
    id: `${scope}.module_02`,
    defaultMessage: 'Journey Module II - Command-Query Responsibility Segregation',
  },
  module_03: {
    id: `${scope}.module_03`,
    defaultMessage: 'Journey Module III - Event Sourcing - Write Model (Command Model)',
  },
  module_04: {
    id: `${scope}.module_04`,
    defaultMessage: 'Journey Module IV - Event Sourcing - Read Model (Query Model)',
  },
  module_05: {
    id: `${scope}.module_05`,
    defaultMessage: 'Events Module I - Event Sourcing - Testowanie i chwila olśnienia',
  },
  module_06: {
    id: `${scope}.module_06`,
    defaultMessage: 'Events Module II - Automatyczne podejmowanie prostych decyzji',
  },
  module_07: {
    id: `${scope}.module_07`,
    defaultMessage: 'Events Module III - Długo trwające procesy biznesowe i Sagi',
  },
  module_08: {
    id: `${scope}.module_08`,
    defaultMessage: 'Events Module IV - Modelowanie czasu',
  },
  module_09: {
    id: `${scope}.module_09`,
    defaultMessage: 'Technical Module I - Transakcje',
  },
  module_10: {
    id: `${scope}.module_10`,
    defaultMessage: 'Technical Module I - Długo żyjące systemy',
  },
  module_11: {
    id: `${scope}.module_11`,
    defaultMessage: 'Technical Module I - Reaktywne przetwarzanie Zdarzeń',
  },
  module_12: {
    id: `${scope}.module_12`,
    defaultMessage: 'Technical Module I - Wysoka dostępność',
  },
}
