import styled from 'styled-components';

import {MEDIA} from '../../constants';
import {getColor, getSpacing, getTypographyColor, getTypographySize, getTypographyWeight} from '../../theme/helpers';

export const Wrapper = styled.div`
  background-color: ${getColor('basicWhite')};
  padding-top: ${getSpacing('xxxl')};
`;

export const TrainingTitle = styled.h1`
  font-size: ${getTypographySize('xxl')};
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('semiBold')};
  padding: ${getSpacing('xl')} 0;
  text-align: center;

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('xxxl')};
    text-align: left;
  }

  @media (min-width: ${MEDIA.MAX}) {
    max-width: 50%;
  }
`;

export const ProgramInfo = styled.span`
  color: ${getTypographyColor('brandBlue')};
  font-size: ${getTypographySize('xl')};
  text-align: center;
  display: inline-block;
  width: 100%;

  @media (min-width: ${MEDIA.MIN}) {
    text-align: left;
  }
`;

export const Description = styled.p`
  color: ${getTypographyColor('grey')};
  font-size: ${getTypographySize('xmd')};
  padding: ${getSpacing('basic')} 0;
  line-height: 1.5;
  text-align: center;

  @media (min-width: ${MEDIA.MIN}) {
    text-align: left;
  }

  @media (min-width: ${MEDIA.MAX}) {
    max-width: 50%;
  }
`

export const TimeframeWrapper = styled.div`
  padding: ${getSpacing('xxl')} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('xxl')};
    justify-content: flex-start;
  }
`;

export const CalendarIcon = styled.img`
  margin-right: ${getSpacing('lg')};
  width: 50px;

  @media (min-width: ${MEDIA.MIN}) {
    width: 78px;
  }
`;

export const TimeframeText = styled.span`
  color: ${getTypographyColor('brandGreen')};
  font-size: ${getTypographySize('xl')};

  @media (min-width: ${MEDIA.MIN}) {
    font-size: ${getTypographySize('xxl')};
  }
`;

export const TrainingContactSection = styled.div`
  min-height: 250px;
  background-color: ${getColor('backgroundGray')};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${getSpacing('lg')} ${getSpacing('md')} ${getSpacing('xxl')} ${getSpacing('md')};

  @media (min-width: ${MEDIA.MIN}) {
    min-height: 350px;
  }
`;

export const TrainingContactText = styled.p`
  color: ${getTypographyColor('grey')};
  line-height: 1.5;
  text-align: center;
  padding: ${getSpacing('lg')};
  font-size: ${getTypographySize('md')};

  @media (min-width: ${MEDIA.MED}) {
    max-width: 50%;
    font-size: ${getTypographySize('lg')};
  }
`;

export const TrainingContactButton = styled.a`
  font-weight: ${getTypographyWeight('light')};
  border-radius: 10px;
  font-size: ${getTypographySize('basic')};
  color: ${getColor('basicWhite')};
  background-color: ${getColor('brandGreen')};
  padding: ${getSpacing('basic')};
  text-decoration: none;
  transition: box-shadow 0.25s;
  text-align: center;

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
  }

  @media (min-width: ${MEDIA.MIN}) {
    padding: ${getSpacing('sm')} ${getSpacing('lg')};
    font-size: ${getTypographySize('md')};
  }
`;

export const CheckTrainingText = styled.p`
  font-size: ${getTypographySize('lg')};
  color: ${getTypographyColor('grey')};
  display: inline-block;
  padding-top: ${getSpacing('xxl')};
  padding-bottom: ${getSpacing('lg')};
  border-top: 1px solid ${getColor('mediumGrey')};
  width: 75%;
  margin-top: 75px;
  text-align: center;
`;
