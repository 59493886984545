const scope = 'components.TrainingPageContent';

export default {
  programInfo: {
    id: `${scope}.programInfo`,
    defaultMessage: 'PROGRAM SZKOLENIA',
  },
  timeframe: {
    id: `${scope}.timeframe`,
    defaultMessage: 'CZAS TRWANIA:',
  },
  days: {
    id: `${scope}.days`,
    defaultMessage: 'DNI',
  },
  calendarImgAlt: {
    id: `${scope}.calendarImgAlt`,
    defaultMessage: 'ikona kalendarza',
  },
  contactText: {
    id: `${scope}.contactText`,
    defaultMessage: 'Skontaktuj się ze mną, a spośród powyższych modułów wybierzemy tematy, które pomogą Twojemu zespołowi stać się bardziej efektywnym zarówno w krótkim, jak i dłuższym okresie czasu.',
  },
  contactButtonMsg: {
    id: `${scope}.contactButtonMsg`,
    defaultMessage: 'NAPISZ DO MNIE',
  },
  checkOtherMsg: {
    id: `${scope}.checkOtherMsg`,
    defaultMessage: 'Sprawdź też inne szkolenie:',
  },
  training: {
    id: `${scope}.training`,
    defaultMessage: 'Szkolenie',
  },
};
